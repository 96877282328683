import ArticleSection from './Article'
import CollectionSection from './Collection'
import ProductSection from './Product'
import QuoteSection from './Quote'
import SectionCard from './Card'

import sectionStyles from './Section.module.scss'
import SectionWrapper from './Wrapper'

const ContentSection = ({ section, additionalConfigs, collections }) => {
  const { items: media } = section.bodyCollection || []

  return (
    media.length > 0 && (
      <div className={sectionStyles.container}>
        {media[0].__typename === 'Product' && (
          <ProductSection
            section={section}
            products={media}
            title={section.title}
            collections={collections}
            additionalConfigs={
              additionalConfigs ? additionalConfigs : undefined
            }
          />
        )}

        {media[0].__typename === 'Collection' && (
          <CollectionSection
            collections={media}
            title={section.title}
            description={section.description}
          />
        )}

        {media[0].__typename === 'Quote' && <QuoteSection quotes={media} />}

        {media[0].__typename === 'PageSectionCard' && (
          <SectionWrapper
            title={section.title}
            description={section.description}>
            <SectionCard cards={media} />
          </SectionWrapper>
        )}

        {media[0].__typename === 'Article' && (
          <ArticleSection
            title={section.title}
            description={section.description}
            articles={media}
          />
        )}
      </div>
    )
  )
}

export default ContentSection
