import algoliasearch from 'algoliasearch/lite'
import {
  InstantSearch,
  useInfiniteHits,
  useInstantSearch
} from 'react-instantsearch-hooks-web'

import ProductGrid from '~/components/Product/Grid'
import RefinementMenu from '~/components/Search/Refinement/Menu'

import { useEffect } from 'react'
import { useAnalytics } from '~/hooks/actions'
import Spinner from '~/components/Common/Spinner'

const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID,
  process.env.ALGOLIA_SEARCH_ONLY_API_KEY
)
const indexName = `${process.env.ALGOLIA_INDEX_PREFIX}_products`

const HitsGrid = ({ title, transformItems }) => {
  const { hits } = useInfiniteHits({
    transformItems
  })
  const { status } = useInstantSearch()
  if (status === 'stalled') {
    return <Spinner size={72} />
  } else if (status === 'idle') {
    if (hits?.length > 0) {
      return <ProductGrid title={title} products={hits} />
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          No results for the filters selected.
        </div>
      )
    }
  } else if (status !== 'error') {
    return <Spinner size={72} />
  } else {
    return (
      <div style={{ textAlign: 'center' }}>
        Something went wrong while trying to find items. Please try again later.
      </div>
    )
  }
}

const ProductSection = ({
  section,
  title,
  products,
  additionalConfigs,
  collections
}) => {
  const { trackProductListViewed } = useAnalytics()

  useEffect(() => {
    trackProductListViewed({
      list: {
        category: section.title ? section.title : '',
        id: section.slug ? section.slug : ''
      }
    })
  }, [section, trackProductListViewed])

  // https://www.algolia.com/doc/api-reference/widgets/hits/react-hooks/#hook
  const transformItems = items => {
    return items
      .map(item => {
        const match = products.find(product => {
          return product.slug == item.handle
        })
        if (match) {
          const lotNumber = item.named_tags.lotNum
            ? item.named_tags.lotNum.replace(':', '')
            : item.named_tags.lotnum
            ? item.named_tags.lotnum.replace(':', '')
            : ''
          return {
            ...match,
            collections: item?.collections,
            price: item?.price,
            estimatedMin: item?.meta?.custom?.estimatedMin,
            estimatedMax: item?.meta?.custom?.estimatedMax,
            startingPrice: item?.meta?.basta?.startingPrice,
            bidAmount: item?.bidAmount,
            bidCount: item?.bidCount,
            auctionStart: item?.meta?.basta?.open, // auctionStart should saved on the product in UTC
            auctionEnd: item?.meta?.basta?.closed,
            lotNumber,
            vendor: item?.vendor,
            useCollectionName: item?.meta?.custom?.useCollectionName
          }
        }
      })
      .filter(product => product !== undefined)
  }

  return (
    <section data-content="products">
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        initialUiState={{
          [indexName]: {
            refinementList: {
              collections: additionalConfigs
                ? additionalConfigs.homepageCollectionFilterOrder
                : []
            }
          }
        }}>
        <RefinementMenu
          homepageCollections={collections}
          attribute="collections"
          dropdownAttributes={['product_type']}
          filterOrder={
            additionalConfigs
              ? additionalConfigs.homepageCollectionFilterOrder
              : undefined
          }
          additionalConfigs={additionalConfigs}
        />
        <HitsGrid title={title} transformItems={transformItems} />
      </InstantSearch>
    </section>
  )
}

export default ProductSection
