import cx from 'classnames'
import ArticleLink from '~/components/Article/Link'
import ResponsiveImage from '~/components/Common/ResponsiveImage'

import styles from './ListItem.module.scss'

const ArticleListItem = ({ article, type }) => {
  const { slug, image, title, publishDate, author } = article

  return (
    <article
      className={cx('font--utility-1', styles.article, {
        [styles.large]: type
      })}>
      <ArticleLink slug={slug} className={styles.image}>
        {image && <ResponsiveImage image={image} maxWidth={type ? 300 : 100} />}
      </ArticleLink>

      <div className={styles.detail}>
        <ArticleLink slug={slug}>
          <p className={styles.title}>{title}</p>
          {author?.name && <p>{author.name}</p>}
        </ArticleLink>

        <p className={styles.tag}>
          Article{publishDate ? `, ${publishDate.slice(0, 7)}` : ''}
        </p>
      </div>
    </article>
  )
}
export default ArticleListItem
