import ArticleListItem from './Item'

const ArticleList = ({ articles, type }) =>
  articles && (
    <ul>
      {articles.map((article, index) => (
        <li key={index}>
          <ArticleListItem article={article} type={type} />
        </li>
      ))}
    </ul>
  )

export default ArticleList
