import spinnerStyles from './Spinner.module.scss'

const Spinner = ({ strokeWidth = 4, size = 48, title = 'Loading...' }) => {
  const r = 16 - strokeWidth
  const C = 2 * r * Math.PI
  const offset = C - (1 / 4) * C

  return (
    <div className={spinnerStyles.spinner}>
      <svg
        strokeWidth={strokeWidth}
        viewBox="0 0 32 32"
        width={size}
        height={size}
        fill="none"
        stroke="currentColor"
        role="img"
        className={spinnerStyles.svg}>
        <title>{title}</title>
        <circle cx="16" cy="16" r={r} opacity={1 / 8} />
        <circle
          cx="16"
          cy="16"
          r={r}
          strokeDasharray={C}
          strokeDashoffset={offset}
          className={spinnerStyles.circle}></circle>
      </svg>
    </div>
  )
}
export default Spinner
