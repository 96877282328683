import { useRouter } from 'next/router'
import React, { useState, useRef, useEffect } from 'react'
import {
  RefinementList,
  useRefinementList,
  useSortBy
} from 'react-instantsearch-hooks-web'
import { useDispatch, useSelector } from 'react-redux'
import { addFilter, removeFilters } from '~/redux/actions'
import refineListItems from '~/utils/refine-list-items'
import rdStyles from './RefinementDropdown.module.scss'

const indexName = `${process.env.ALGOLIA_INDEX_PREFIX}_products`

const CustomReset = props => {
  const filters = useSelector(state => state.filters)
  const { canRefine, refine, items } = useRefinementList(props)
  const dispatch = useDispatch()
  const handleReset = () => {
    if (canRefine) {
      props.additionalConfigs.homepageCollectionFilterOrder.forEach(config => {
        if (
          items.some(item => item.value === config && item.isRefined === false)
        ) {
          filters.forEach(filter => refine(filter))
          dispatch(removeFilters())
          dispatch(addFilter(config))
          refine(config)
        }
      })
      if (
        items.some(
          item => item.value === 'show_empty' && item.isRefined === false
        )
      )
        refine('show_empty')
    }
  }

  return (
    <button
      className={`cta cta--default ${rdStyles.refinementDropdownReset} `}
      onClick={handleReset}>
      Reset
    </button>
  )
}

const SortingOptions = props => {
  const { currentRefinement, options, refine } = useSortBy(props)

  return (
    <div className={rdStyles.sortingOptions}>
      <h2 className={rdStyles.sortingOptionsHeader}>Sort By</h2>
      <div className={rdStyles.sortingOptionsList}>
        {options.map(option => (
          <button
            key={`sorting-option__${option.value}`}
            className={`${rdStyles.item} ${
              currentRefinement === option.value ? rdStyles.itemActive : ''
            }`}
            onClick={() => refine(option.value)}>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  )
}

const RefinementDropdown = props => {
  const { attributes, additionalConfigs } = props
  const [isActive, setIsActive] = useState(false)
  const routerPath = useRouter().asPath
  const wrapperRef = useRef(null)

  useEffect(() => {
    // Detect clicks outside of the passed ref
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsActive(false)
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [wrapperRef])

  // When the dynamic route change close the dropdown
  useEffect(() => {
    setIsActive(false)
  }, [routerPath])

  // Toggle the dropdown
  const toggleDropdown = () => {
    setIsActive(!isActive)
  }

  const refinementLists = attributes.map((attribute, index) => (
    <RefinementList
      key={`refinement-list-${index}`}
      classNames={{
        root: rdStyles.refinementList,
        label: rdStyles.refinementListLabel,
        item: rdStyles.refinementListItem,
        checkbox: rdStyles.refinementListCheckbox,
        labelText: rdStyles.refinementListLabelText,
        count: rdStyles.refinementListCount,
        selectedItem: rdStyles.refinementListItemActive
      }}
      attribute={attribute}
      transformItems={refineListItems}
    />
  ))

  return (
    <div
      ref={wrapperRef}
      className={`${rdStyles.refinementDropdown}
        ${
          isActive
            ? rdStyles.refinementDropdownActive
            : rdStyles.refinementDropdownInActive
        }`}>
      <button
        className={rdStyles.refinementDropdownTrigger}
        onClick={() => toggleDropdown()}>
        Filter
        <span
          className={`icon icon--chevron-${isActive ? 'up' : 'down'} ${
            rdStyles.refinementDropdownIcon
          } `}></span>
      </button>
      <div
        className={`${rdStyles.refinementDropdownContent} ${
          isActive ? rdStyles.refinementDropdownContentActive : ''
        }`}>
        {refinementLists}
        <CustomReset
          additionalConfigs={additionalConfigs}
          attribute={props.resetAttribute}
          filterOrder={props.resetFilterOrder}
        />
        <SortingOptions
          initialIndex={indexName}
          items={[
            { label: 'Featured', value: indexName },
            { label: 'Newest', value: `${indexName}_newest_desc` },
            { label: 'Estimated Min', value: `${indexName}_estimatedmin_asc` },
            { label: 'Estimated Max', value: `${indexName}_estimatedmax_desc` }
          ]}
        />
      </div>
    </div>
  )
}
export default RefinementDropdown
