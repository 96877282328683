import ResponsiveImage from '~/components/Common/ResponsiveImage'
import styles from './SectionCard.module.scss'

const SectionCard = ({ cards }) => {
  return (
    <div className={styles.container}>
      {cards.map(card => {
        const { id } = card.sys
        const { heading, subheading, description, mainImage } = card
        return (
          <div key={id} className={styles.card}>
            {mainImage && <ResponsiveImage image={mainImage} />}
            {(heading || subheading || description) && (
              <div className={styles.content}>
                {heading && <h1 className="font--body-1">{heading}</h1>}
                {subheading && <h2 className="font--body-2">{subheading}</h2>}
                {description && <p className="font--body-1">{description}</p>}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
export default SectionCard
