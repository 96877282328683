import styles from './Wrapper.module.scss'

const SectionWrapper = ({ title, description, children }) => {
  return (
    <div className={styles.container}>
      {title && <h1 className={`font--body-1 ${styles.heading}`}>{title}</h1>}

      {description && (
        <p className={`font--body-1 ${styles.description}`}>{description}</p>
      )}
      {children}
    </div>
  )
}
export default SectionWrapper
