import HeroModule from '~/components/Content/Hero/HeroModule'
import ContentSection from '~/components/Content/Section'

const getHomepageCollectionFilterOrder = sections => {
  let homepageCollectionFilterOrder = []

  sections.forEach(section => {
    const sectionItems = section.bodyCollection.items
    const sectionType = sectionItems[0]?.__typename

    if (sectionType === 'Collection') {
      // Collection handles are created here asssuming Contentful collection slug uses this naming convention: "{collection-name}-collection"
      homepageCollectionFilterOrder = sectionItems.map(item => {
        if (
          item.preSelectedHomepageFilter ||
          item.preSelectedHomepageFilter === null
        ) {
          return item.slug.replace('-collection', '')
        }
      })
      homepageCollectionFilterOrder = homepageCollectionFilterOrder.filter(
        item => item !== undefined
      )
    }
  })

  return homepageCollectionFilterOrder
}

const ContentPage = ({ page, className, isAuction }) => {
  const { items: sections } = page.bodyCollection || []
  const additionalConfigs = {}

  if (page.slug === 'home' || isAuction) {
    additionalConfigs.homepageCollectionFilterOrder =
        getHomepageCollectionFilterOrder(sections)
  }

  return (
    <div className={className}>
      {page.heroModule && <HeroModule data={page.heroModule} />}
      {sections.map(section => {
        if ((page.slug === 'home' || isAuction) && section.bodyCollection.items.length > 0) {
          const sectionType = section.bodyCollection.items[0]?.__typename

          if (sectionType === 'Product') {
            const collectionSection = sections.find(section => {
              return (
                section.bodyCollection.items[0]?.__typename === 'Collection'
              )
            })
            return (
              <ContentSection
                key={section.sys.id}
                section={section}
                collections={collectionSection.bodyCollection.items}
                additionalConfigs={
                  Object.keys(additionalConfigs).length !== 0
                    ? additionalConfigs
                    : undefined
                }
              />
            )
          } else {
            return null
          }
        } else {
          return <ContentSection key={section.sys.id} section={section} />
        }
      })}
    </div>
  )
}

export default ContentPage
