import { useRefinementList } from 'react-instantsearch-hooks-web'
import RefinementDropdown from '../Dropdown'
import { useSelector, useDispatch } from 'react-redux'
import { addFilter, removeFilter, removeFilters } from '~/redux/actions'
import rmStyles from './RefinementMenu.module.scss'
import { useEffect } from 'react'
import { toSlug } from '~/utils'

function RefinementMenu(props) {
  const { filterOrder, additionalConfigs, homepageCollections } = props
  const { items, refine } = useRefinementList(props)
  const filters = useSelector(state => state.filters)
  const dispatch = useDispatch()
  if (filterOrder) {
    const filterOrder = props.filterOrder
    items.sort(
      (a, b) => filterOrder.indexOf(a.value) - filterOrder.indexOf(b.value)
    )
  }
  const homepageCollectionSlugs = homepageCollections.map(homepageCollection =>
    toSlug(homepageCollection.name)
  )
  useEffect(() => {
    if (filters[0] === false) {
      dispatch(removeFilters())
      props.additionalConfigs.homepageCollectionFilterOrder.forEach(config => {
        dispatch(addFilter(config))
      })
    } else {
      props.additionalConfigs.homepageCollectionFilterOrder.forEach(config => {
        refine(config)
      })
      filters.forEach(filter => {
        refine(filter)
      })
    }
  }, [])

  return (
    <div className={rmStyles.refinement}>
      <ul className={rmStyles.refinementList}>
        {items
          .filter(item => homepageCollectionSlugs.includes(item.label))
          .map(
            item =>
              item.value !== 'show_empty' && (
                <li key={`refinement-option-${item.label}`}>
                  <label
                    className={`${rmStyles.refinementListLabel} ${
                      item.isRefined ? rmStyles.refinementListLabelActive : ''
                    }`}>
                    <input
                      className={rmStyles.refinementListCheckbox}
                      type="checkbox"
                      checked={item.isRefined}
                      value={item.value}
                      onChange={event => {
                        if (event.target.checked) {
                          dispatch(addFilter(event.target.value))
                        } else {
                          dispatch(removeFilter(event.target.value))
                        }
                        refine(item.value)
                      }}
                    />
                    {item.label}
                  </label>
                </li>
              )
          )}
      </ul>
      <RefinementDropdown
        additionalConfigs={additionalConfigs}
        resetAttribute={props.attribute}
        resetFilterOrder={props.filterOrder}
        attributes={props.dropdownAttributes}
      />
    </div>
  )
}

export default RefinementMenu
