/** @jsxImportSource theme-ui */
import Link from '~/components/Generic/Link'
import { useAnalytics } from '~/hooks/actions'

const CollectionLink = ({
  id,
  title,
  slug,
  description,
  imageurl,
  children,
  sx,
  ...props
}) => {
  const { trackCollectionClicked } = useAnalytics()

  const url = `/shop/?collection=${slug}`

  return (
    <Link
      href={url}
      onClick={() =>
        trackCollectionClicked({
          id,
          title,
          slug,
          imageurl,
          description: description.description
        })
      }
      sx={{
        ...sx
      }}
      {...props}>
      {children}
    </Link>
  )
}

export default CollectionLink
